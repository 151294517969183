<template>
  <div class="mb-12" id="equity-profile">
    <div class="content-container text-xl">Equity Profile % (Yrs)</div>
    <div class="text-center" style="font-weight: normal">Includes initial equity amount</div>
    <div class="content-container" style="font-weight: normal">Projected amounts are shaded transparent</div>
    <apexchart height="400" class="md-12" :options="barOptions" :series="chartSeries" />
  </div>
</template>

<script>
export default {
  name: 'EquityProfile',
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    barOptions: {
      get: function (props) {
        const colors = props.$constants.colors
        const options = {
          chart: {
            type: 'bar',
            height: 500,
            stacked: true,
            toolbar: {
              show: false
            },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
              colors: ['#fff']
            },
            formatter: function () {
              return ''
            }
          },
          stroke: {
            width: 4
          },
          colors: [colors.pfidaPurple, colors.pfidaDustBlue, colors.pfidaSalmon],
          yaxis: {
            title: {
              text: undefined
            },
            min: 0,
            max: 100,
            tickAmount: 10,
            labels: {
              style: {
                fontSize: '16px',
                fontFamily: 'jeko-medium'
              },
              formatter: function (val) {
                return val?.toFixed(0) + '%'
              }
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            },
            x: {
              formatter: function (val) {
                return `Year ${val + 1}`
              }
            },
            style: {
              fontSize: '16px',
              fontFamily: undefined
            },
            followCursor: true
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
            fontSize: '16px',
            fontFamily: 'jeko-medium',
            markers: { fillColors: [colors.pfidaPurple, colors.pfidaDustBlue, colors.pfidaSalmon] }
          },
          xaxis: {
            categories: props.isSmallerScreen
              ? props.chartData.equityProfileYearsResponsive
              : props.chartData.equityProfileYears,
            axisBorder: {
              show: true,
              color: '#8e9699',
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#8e9699',
              height: 5,
              offsetX: 0,
              offsetY: 0
            },
            labels: {
              hideOverlappingLabels: true,
              rotate: -90,
              style: {
                fontSize: props.isSmallerScreen ? '16px' : '14px',
                fontFamily: 'jeko-medium',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label'
              },
              formatter: function (val) {
                // ? this is so the Graph starts with 1 instead of 0
                return val + 1
              },
              offsetX: props.isSmallerScreen ? -1 : 0,
              offsetY: 0
            }
          },
          fill: {
            colors: [
              ({ seriesIndex, dataPointIndex }) => {
                const dataindex = props.isSmallerScreen
                  ? props.chartData.equityProfileYearsResponsive[dataPointIndex] + 1
                  : dataPointIndex + 1

                if (dataindex <= props.financeDetails.term_elapsed_years) {
                  if (seriesIndex === 0) {
                    return colors.pfidaPurple
                  } else if (seriesIndex === 1) {
                    return colors.pfidaDustBlue
                  }
                } else {
                  if (seriesIndex === 0) {
                    return '#725FA3'
                  } else if (seriesIndex === 1) {
                    return colors.pfidaDustBlue
                  }
                }
              }
            ],
            opacity: 1
          }
        }
        return options
      }
    },
    chartData() {
      const index = this.$route.params.index - 1
      return this.$store.state.chartsData[index]
    },
    financeDetails() {
      const index = this.$route.params.index - 1
      return this.$store.state.financeDetails[index]
    },
    chartSeries() {
      if (this.isSmallerScreen) {
        return this.chartData.equityProfileResponsive
      }
      return this.chartData.equityProfile
    }
  }
}
</script>

<style scoped>
.content-container {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
</style>
