
<script>
import InvestorHubBrief from '@/layouts/components/pics/InvestorHubBrief.vue'
import userAPI from '../../../http/requests/user/userAPI'
import InvestorHubDeck from '@/layouts/components/pics/InvestorHubDeck.vue'
import InvestorHubOnepager from '@/layouts/components/pics/InvestorHubOnepager.vue'

export default {
  components: {
    InvestorHubDeck,
    InvestorHubOnepager,
    InvestorHubBrief,
  },
  computed: {
    name() {
      return this.$store.state.user.details.name
    },
    subscriptionFormLink() {
      return this.$store.state.investmentDetails.pfidac.subscription_form_link
    },
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.$constants.colors.pfidaDustBlue,
      }
    },
    circleTextCssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.$constants.colors.pfidaSalmon,
      }
    }
  },
  methods: {
    openEventbrite() {
      window.open(
        'https://www.eventbrite.co.uk/e/invest-in-pfida-live-session-with-the-founders-limited-spaces-registration-662983701097',
        '_blank'
      )
    },
    openSubForm() {
      window.open(this.subscriptionFormLink, '_blank')
    },
  },
  mounted() {
    userAPI.postEquityInvestmentVisited()
    this.$store.commit('SET_EQUITY_INVESTMENT_INTEREST', '')

    if (!this.$store.state.investmentDetails.pfidac.show_equity_hub) {
      // Go to Dashboard if equity investment doesnt exist
      this.$router.push('/')
    }
  }
}
</script>

<template>
  <div class="max-w-screen-xl mx-auto">
    <vx-card>

    <div class="promotion-header p-2 max-w-3xl mx-auto text-pretty mb-14">
        The content of this promotion has not been approved by an authorised person within the meaning of
        the Financial Services and Markets Act 2000. Reliance on this promotion for the purpose of engaging
        in any investment activity may expose an individual to a significant risk of losing all of the property
        or other assets invested.
    </div>
    <div class="flex flex-col gap-28">
      <b-row class="flex items-center gap-12 md:gap-0">
        <b-col sm="12" md="8">
          <div class="flex flex-col gap-2">
           <h1 class="text-4xl">{{ name }}, welcome to your Investor Hub</h1>
              <p>
                We’re excited to share Funding Round 3 has reached its £2m target in just 3 days.
                Due to overwhelming demand, we are now oversubscribing - but the window for additional investment is limited.
              </p>
              <p>
                This Investor Hub is your backstage pass to all the key details.
                Explore the information here to help you make an informed decision, and act quickly if you’d like to secure your place in this exclusive opportunity before it closes.
              </p>

              <p>
                <b>Updated close date</b>: 30 September 2024
              </p>
            <b-button class="self-center" variant="primary" @click="openSubForm()" size="lg">Complete subscription form</b-button>
          </div>
        </b-col>
        <b-col sm="12" md="4">
          <div class="pie-chart pt-0">
            <svg viewBox="0 0 36 36" class="circular-chart color" :style="cssVars">
              <path
                class="circle"
                :stroke-dasharray="100"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="12.5" class="circle-label text-[3px]" :style="cssVars">Now</text>
              <text x="18" y="18.5" class="percentage text-[4px]" :style="circleTextCssVars">over</text>
              <text x="18" y="23.5" class="percentage text-[4px]" :style="circleTextCssVars">subscribing</text>
            </svg>
          </div>
        </b-col>
      </b-row>

          <!-- Eventbite -->
      <!-- <b-row class="text-center flex items-center">
        <b-col sm="12">
            <a href="https://www.eventbrite.co.uk/e/978201175047?aff=oddtdtcreator" target="_blank">
            <img src="@repo/assets/brand/images/investor-hub-webinar"/></a>
        </b-col>
      </b-row> -->

      <!-- Youtube Video -->
     <b-row class="flex items-center">
          <b-col sm="12" md="5" class="flex flex-col gap-2">
            <h2 class="text-4xl">Catch up on our webinar</h2>
            <p class="text-pretty">
                Watch a recap of our investor call with the founders as they share details about the opportunity.
            </p>
          </b-col>
          <b-col sm="12" md="7">
            <div class="video-container">
            // TODO: Replace video URL
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hw4-UBdHxYI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </b-col>
        </b-row>

      <div class="flex flex-col">
        <h1 class="text-center text-4xl">Download our investor materials</h1>
        <b-row class="text-center">
          <b-col sm="12" md="4" class="py-4">
            <InvestorHubOnepager />
          </b-col>
          <b-col sm="12" md="4" class="py-4">
            <InvestorHubDeck title="View the Pfida investor brief" />
          </b-col>
          <b-col sm="12" md="4" class="py-4">
            <InvestorHubBrief />
          </b-col>
        </b-row>
        <b-button class="self-center" variant="primary" @click="openSubForm()" size="lg">Complete subscription form</b-button>
      </div>



    </div>
    </vx-card>
    <div id="Footer" class="mt-6 text-sm">
      <p>
        This communication is exempt from the general restriction (section 21 of the Financial Services and Markets Act
        2000) on the communication of invitations or inducements to engage in investment activity on the ground that it
        is made to a high net worth investor or a self-certified sophisticated investor or an advised investor, also
        known as a certified sophisticated investor. Find out more about investor type categories and requirements by
        <a target="_blank" href="https://help.pfida.com/en/articles/7733971-understanding-investor-type-categories"
          >clicking here.</a
        >
      </p>
      <p>
        The content of this communication has not been approved by an authorised person, which approval is, unless this
        exemption or any other exemption applies, required by section 21 of the Financial Services and Markets Act 2000.
      </p>

      <p>
        Reliance on this communication for the purpose of engaging in any investment activity may expose you to a
        significant risk of losing all of the property invested or of incurring additional liability.
      </p>

      <p>
        If you are in any doubt about the investment to which this communication relates, you should consult an
        authorised person specialising in advising on investments of this kind.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hover-effect:hover {
  padding-left: 20px;
}

.img-holder {
  position: relative;
  cursor: pointer;
}

@media (min-width: 720px) {
  .pie-chart {
    padding-top: 4em;
  }
}

.promotion-header {
  color: black;
  font-weight: bold;
  text-align: center;
  border: 2px solid black;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

// !
.pie-chart-subtext {
  font-size: 14px;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
}

.circular-chart.color .circle {
  stroke: var(--color);
}

.percentage {
  fill: var(--color);
  font-size: 0.315em;
  text-anchor: middle;
}

.circle-label {
  font-size: 0.215em;
  text-anchor: middle;
}

.webinar-btn {
  padding-left: 30px;
  padding-right: 30px;
}

// !
</style>
