/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import themeConfig from '@/../themeConfig.js';
import colors from '@/../themeConfig.js';

export const defaultState = {
  chartsData: [],
  financeDetails: [],
  investmentDetails: {},
  referral: '',
  equityInvestmentRedirect: false,
  showInvestorTypePage: false,
};

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
// const is_touch_device = () => {
//   var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
//   var mq = function (query) {
//     return window.matchMedia(query).matches
//   }
//   if ('ontouchstart' in window || window.DocumentTouch) {
//     return true
//   }
//   // include the 'heartz' as a way to have a non matching MQ to help terminate the join
//   // https://git.io/vznFH
//   var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
//   return mq(query)
// }

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////
const state = {
  ...defaultState,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  theme: themeConfig.theme || 'light',
  themePrimaryColor: colors.primary,
  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
};

export default state;
