/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
==========================================================================================*/
import _ from 'lodash';

import authInitialState from './authModule/AuthModuleState';
import calculatorInitState from './calculatorsModule/calculatorsModuleState';
import { defaultState } from './state';
import userInitalState from './userModule/userModuleState';

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  RESET_STATE(state) {
    const initialState = {
      auth: authInitialState,
      user: userInitalState,
      calculators: calculatorInitState,
      ...defaultState,
    };

    _.forOwn(initialState, (value, key) => {
      state[key] = _.cloneDeep(value);
    });
  },
  RESET_USER_STATE_BEFORE_LOGIN(state) {
    const initialState = {
      auth: authInitialState,
      user: userInitalState,
      calculators: calculatorInitState,
    };

    _.forOwn(initialState, (value, key) => {
      state[key] = _.cloneDeep(value);
    });
  },

  SET_EQUITY_INVESTMENT_INTEREST(state, payload) {
    state.equityInvestmentRedirect = payload;
  },

  // !Business logic to be stored in Actions not Mutations
  SET_CHART_DATA(state, payload) {
    const chartsData = [];
    payload.forEach((finance) => {
      const chartData = {
        paymentProfile: {},
      };
      if (finance?.equity_released) {
        chartData.equityChart = [
          finance.remaining_finance_rounded,
          Math.abs(finance.equity_released),
          finance.total_equity_without_deposit_rounded,
          finance.deposit,
        ];
      } else {
        chartData.equityChart = [
          finance.remaining_finance_rounded,
          finance.total_equity_without_deposit_rounded,
          finance.deposit,
        ];
      }
      chartData.projectedYears = [
        {
          name: 'Years Elapsed',
          data: [Math.abs(finance.term_elapsed_decimal)],
        },
        {
          name: 'Years Remaining',
          data: [finance.remaining_term_decimal],
        },
      ];

      chartData.isShowingLease = false;
      if (finance.lease_term_years || finance.lease_term_months || finance.lease_term_decimal) {
        chartData.projectedYears[0].data.push(Math.abs(finance.lease_term_elapsed_decimal));
        chartData.projectedYears[1].data.push(Math.abs(finance.lease_term_decimal));
        chartData.isShowingLease = true;
      }

      chartData.nextPaymentBreakdown = [
        {
          name: 'Equity Purchase',
          data: [finance.next_equity_payment],
        },
        {
          name: 'Rent',
          data: [finance.next_rent_payment],
        },
      ];
      finance.rent_arrears_remaining_rounded
        ? chartData.nextPaymentBreakdown.splice(1, 0, {
            name: finance.product_type === 'eb' ? 'Rent Rolled Over' : 'Rent Arrears',
            data: [finance.rent_arrears_remaining_rounded],
          })
        : '';
      chartData.upcomingPayments = [
        {
          name: 'Equity Purchase',
          data: [],
        },
        {
          name: 'Rent',
          data: [],
        },
      ];
      chartData.amountLabels = [];
      const nextPayments = finance.latest_projected_payments.monthly_projections_data;
      Object.entries(nextPayments)?.forEach(([key, val]) => {
        chartData.upcomingPayments[1].data.push(val.rent);
        chartData.upcomingPayments[0].data.push(val.equity);
        chartData.amountLabels.push(key);
      });

      // ? LifeTime Profile
      chartData.lifeTimeProfile = [
        {
          name: 'Equity Purchase',
          data: [
            finance.original_projected_payments.estimated_total_equity_purchases,
            finance.latest_projected_payments.estimated_total_equity_purchases,
          ],
        },
        {
          name: 'Rent',
          data: [
            finance.original_projected_payments.estimated_total_rental_payments,
            finance.latest_projected_payments.estimated_total_rental_payments,
          ],
        },
      ];
      if (finance.saving) {
        chartData.lifeTimeProfile.push({
          name: 'Savings',
          data: [0, finance.saving],
        });
      }

      // ? Payment Profile
      let responsiveYearGap = 1;
      const futureYearInfo = finance.latest_projected_payments.future_year_info;
      if (Object.keys(futureYearInfo).length > 10 && Object.keys(futureYearInfo).length < 20) {
        responsiveYearGap = 2;
      } else if (Object.keys(futureYearInfo).length >= 20 && Object.keys(futureYearInfo).length <= 30) {
        responsiveYearGap = 3;
      }

      chartData.paymentProfile.years = [];
      for (let i = 0; i < Math.ceil(finance.total_term_decimal); i++) {
        chartData.paymentProfile.years.push(i);
      }
      chartData.paymentProfile.yearsResponsive = [];
      for (let i = 0; i < Math.ceil(finance.total_term_decimal); i++) {
        if (i === finance.total_term_years || i === finance.total_term_years - 1) {
          chartData.paymentProfile.yearsResponsive.push(i);
        } else {
          if (i % responsiveYearGap == 0) {
            chartData.paymentProfile.yearsResponsive.push(i);
          }
        }
      }

      chartData.paymentProfile.payments = [
        {
          name: 'Equity Purchase',
          type: 'bar',
          data: chartData.paymentProfile.years.map((x) => {
            const yearEquity =
              finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.year_equity;
            return yearEquity || 0;
          }),
        },
        {
          name: 'Rent',
          type: 'bar',
          data: chartData.paymentProfile.years.map((x) => {
            const yearRent = finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.year_rent;
            return yearRent || 0;
          }),
        },
        {
          name: 'Original Projection',
          type: 'line',
          data: chartData.paymentProfile.years.map((x) => {
            const yearEquity =
              finance.original_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.year_equity;
            return yearEquity || 0;
          }),
        },
      ];

      chartData.paymentProfile.paymentsResponsive = [
        {
          name: 'Equity Paid',
          type: 'bar',
          data: chartData.paymentProfile.yearsResponsive.map((x) => {
            const yearEquity =
              finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.year_equity;
            return yearEquity || 0;
          }),
        },
        {
          name: 'Rent Paid',
          type: 'bar',
          data: chartData.paymentProfile.yearsResponsive.map((x) => {
            const yearRent = finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.year_rent;
            return yearRent || 0;
          }),
        },
        {
          name: 'Original Projection',
          type: 'line',
          data: chartData.paymentProfile.yearsResponsive.map((x) => {
            const yearEquity =
              finance.original_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.year_equity;
            return yearEquity || 0;
          }),
        },
      ];

      // This is to find the max amount of rent+equity in a year, to set max on Y axis of chart
      chartData.paymentProfile.maxPayment = Math.max(
        ...chartData.paymentProfile.years.map((x) => {
          const year = finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`];
          return (year?.year_equity || 0) + (year?.year_rent || 0);
        })
      );

      //? Equity Profile
      let yearsShowingResponsiveEquity = 1;
      chartData.equityProfileYears = [];
      for (let i = 0; i < Math.ceil(finance.total_term_decimal); i++) {
        chartData.equityProfileYears.push(i);
      }

      chartData.equityProfileYearsResponsive = [];
      if (finance.total_term_decimal > 10 && finance.total_term_decimal < 20) {
        yearsShowingResponsiveEquity = 2;
      } else if (finance.total_term_decimal >= 20 && finance.total_term_decimal <= 30) {
        yearsShowingResponsiveEquity = 3;
      }
      for (let i = 0; i < Math.ceil(finance.total_term_decimal); i++) {
        if (i === finance.total_term_years || i === finance.total_term_years - 1) {
          chartData.equityProfileYearsResponsive.push(i);
        } else {
          if (i % yearsShowingResponsiveEquity == 0) {
            chartData.equityProfileYearsResponsive.push(i);
          }
        }
      }
      chartData.equityProfile = [
        {
          name: 'Equity Purchase %',
          type: 'bar',
          data: chartData.equityProfileYears.map((x) => {
            const equity_purchase_percentage =
              finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.equity_purchase_percentage;
            return equity_purchase_percentage || 0;
          }),
        },
        {
          name: 'Outstanding Equity %',
          type: 'bar',
          data: chartData.equityProfileYears.map((x) => {
            const equity_remaining_percentage =
              finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]
                ?.equity_remaining_percentage === 0
                ? undefined
                : finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]
                    ?.equity_remaining_percentage;
            return equity_remaining_percentage || null;
          }),
        },
        {
          name: 'Original Projection',
          type: 'line',
          data: chartData.equityProfileYears.map((x) => {
            const equity_purchase_percentage =
              finance.original_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]
                ?.equity_purchase_percentage;

            return equity_purchase_percentage || 0;
          }),
        },
      ];

      chartData.equityProfileResponsive = [
        {
          name: 'Equity Purchase %',
          type: 'bar',
          data: chartData.equityProfileYearsResponsive.map((x) => {
            const equity_purchase_percentage =
              finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]?.equity_purchase_percentage;
            return equity_purchase_percentage || 0;
          }),
        },
        {
          name: 'Outstanding Equity %',
          type: 'bar',
          data: chartData.equityProfileYearsResponsive.map((x) => {
            const equity_remaining_percentage =
              finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]
                ?.equity_remaining_percentage === 0
                ? undefined
                : finance.latest_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]
                    ?.equity_remaining_percentage;
            return equity_remaining_percentage || null;
          }),
        },
        {
          name: 'Original Projection',
          type: 'line',
          data: chartData.equityProfileYearsResponsive.map((x) => {
            const equity_purchase_percentage =
              finance.original_projected_payments?.future_year_info?.[`end_of_year_${x + 1}`]
                ?.equity_purchase_percentage;
            return equity_purchase_percentage || 0;
          }),
        },
      ];
      chartsData.push(chartData);
    });
    state.chartsData = chartsData;
  },

  SET_FINANCE_DETAILS(state, payload) {
    state.financeDetails = payload;
  },
  SET_INVESTMENT_DETAILS(state, payload) {
    state.investmentDetails = payload;
  },
  SET_REFERRAL(state, payload) {
    state.referral = payload;
  },
};

export default mutations;
